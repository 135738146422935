.applicationTabs {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 48px;
	flex-direction: column;
	width: 80%;
	max-width: 1200px;
	margin: -80px auto 0;
	background-color: var(--light-gray);
	padding: 64px 56px;
	gap: 48px;
	border-radius: 12px;
	z-index: 3;
}

.application_tab_tabs {
	display: flex;
	align-items: center;
	gap: 24px;
	flex-wrap: wrap;
}

.application_section_header h2,
.application_section_header p {
	text-align: center;
}

.application_section_header h2 {
	font-size: 40px;
	font-weight: 700;
	line-height: 48px;
	letter-spacing: -0.01em;
}

.application_section_header p {
	margin-top: 8px;
	color: var(--alt-text-color-tint);
	font-size: 14px;
	font-weight: 400;
	line-height: 20.02px;
}

.active_tab_applications {
	background-color: var(--white-color);
}

.application_tab:hover {
	background-color: var(--white-color);
}

.application_tab_text {
	font-size: 1.36rem;
	color: var(--alt-text-color-tint);
}

.application_tab_indicator {
	display: none;
	width: 8px;
	height: 100%;
	position: absolute;
	background-color: var(--primary);
	top: 0;
	left: 0;
}

.application_tab_indicator_show {
	display: block;
}

.application_body {
	flex: 1;
	max-width: 900px;
	border: 0.2px solid var(--neutal-60);
	min-height: 532px;
	padding: 32px;
	border-radius: 0px 6px 6px 6px;
}

@media (max-width: 767px) {
	.application_tab_container {
		flex-direction: column;
	}

	.applicationTabs {
		padding: 4rem;
		margin: -50px auto 0;
	}

	.application_tab_tabs {
		flex-direction: column;
		width: 100%;
		border-right: 0.2px solid var(--neutal-60);
	}
}

@media (max-width: 480px) {
	.applicationTabs {
		padding: 2rem;
		margin: -30px auto 0;
	}
}

@media (max-width: 339px) {
	.applicationTabs {
		margin: -0px auto 0;
	}
} ;
