.container {
	position: relative;
	display: flex;
	justify-content: center;
	height: calc(100vh - 72px);
	max-height: 720px;
}

.container::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0.6),
		rgba(0, 0, 0, 0.3)
	);
	z-index: 1;
	pointer-events: none;
}

.firstCover {
	position: relative;
	margin-top: 200px;
	z-index: 1;
}

.firstCover h1 {
	font-size: 48px;
	font-weight: 700;
	line-height: 61.44px;
	text-align: center;
	color: var(--white-color);
	text-align: center;
}

.firstCover p {
	font-size: 20px;
	font-weight: 450;
	line-height: 28px;
	text-align: center;
	color: var(--white-color);
	margin-top: 42px;
	text-align: center;
}

.firstCover > div {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.firstCover button {
	padding: 0px 42.5px;
	height: 56px;
}

.container img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	object-fit: cover;
}

.programCardContainer {
	display: flex;
	flex-direction: column;
	width: 30%;
	flex-grow: 1;
	min-height: 245px;
	padding: 40px 40px;
	border-radius: 6px;
	background: var(--white-color);
	border: 0.3px solid var(--white-color);
}

.iconBackground {
	padding: 1rem;
	margin-bottom: 0.5rem;
	width: 4rem;
	height: 4rem;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f4f4f4;
}

.programTitle {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	color: var(--text-color);
}

.programSubtitle {
	font-size: 14px;
	font-weight: 450;
	line-height: 20.02px;
	color: var(--light-text-color-2);
}

.cardLinks {
	margin-top: 3rem;
}

.cardLinks a {
	text-decoration: none;
	color: #0052cc;
	font-weight: 500;
}

.cardLinks a:hover {
	text-decoration: underline;
}

.home_footer {
	box-shadow: 0px 2px 6px 0px var(--box_shadow);
	padding: 16px;
	bottom: 0;
	position: relative;
	width: 100%;
}

.copyright {
	font-size: 14px;
	font-weight: 450;
	line-height: 20.02px;
	color: var(--light-text-color);
}

.fab {
	background-color: var(--primary);
	width: 2.7rem;
	height: 2.7rem;
	border-radius: 4px;
	position: absolute;
	right: 20px;
	top: -70px;
	transition: all 0.3s ease;
	z-index: 9000;
}

.fab:hover {
	bottom: 5%;
	cursor: pointer;
}

@media (min-width: 768px) {
	.cardsContainer {
		padding: 40px;
		margin: -50px auto;
		margin-bottom: 50px;
		width: 90%;
	}

	.card {
		padding: 50px;
	}
}

@media (max-width: 900px) {
	.programCardContainer {
		width: 100%;
	}
}

@media (max-width: 768px) {
	.container {
		max-height: 70vh;
	}

	.firstCover {
		margin-top: 100px;
	}

	.firstCover h1 {
		font-size: 36px;
	}

	.programCardContainer p {
		text-align: left;
	}
}

@media (max-width: 540px) {
	.firstCover {
		margin-top: 50px;
	}
}
